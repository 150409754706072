<template>
  <div v-loading="loading" class="member-shop-point-exchange-product-edit">
    <div
      v-if="!loaded"
      v-loading="!loaded"
      element-loading-background="rgba(0, 0, 0, 0)"
      class="w-full"
      style="height: 500px"
    />
    <PageTitle
      icon="chevron_left"
      title="佈告欄設定"
      hideBtn
      style="margin-bottom: 24px"
      @iconClick="$router.go(-1)"
    />
    <template v-if="loaded">
      <MemberCenterBulletinEditInfoBlock class="card-container form-wrapper" :productData="productData" :context.sync="context" />
    </template>
    <PageFixedFooter :confirmLoading="loading" @cancel="onCancel" @confirm="onSubmit" />
  </div>
</template>

<script>
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import { defineComponent, computed, ref, onMounted, reactive } from 'vue'
import MemberCenterBulletinEditInfoBlock from './components/MemberCenterBulletinEditInfoBlock.vue'
import { FindMemberCenterAd, CreateMemberCenterAd, UpdateMemberCenterAd } from '@/api/bulletin'
import { useRoute, useRouter } from 'vue-router/composables'
import formsContext from './context'
import store from '@/store'

export default defineComponent({
  name: 'MemberCenterBulletinEdit',
  components: {
    PageFixedFooter,
    MemberCenterBulletinEditInfoBlock,
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const shopId = computed(() => store.getters.shop)
    const memberCenterAdId = computed(() => route.params.id)
    const productData = ref({})
    const loading = ref(false)
    const loaded = ref(false)

    const context = reactive({
      type: 'classTicket',
    })

    const findMemberCenterAd = async (payload) => {
      const [res, err] = await FindMemberCenterAd({
        shopId: shopId.value,
        id: memberCenterAdId.value,
      })
      if (err) return window.$message.error(err)
      productData.value = res
    }

    const createMemberCenterAd = async (payload) => {
      const [, err] = await CreateMemberCenterAd(payload)
      if (err) throw err
      window.$message.success('新增佈告欄成功！')
    }
    const updateMemberCenterAd = async (payload) => {
      const [, err] = await UpdateMemberCenterAd(payload)
      if (err) throw err
      window.$message.success('更新佈告欄成功！！')
    }

    const onSubmit = async () => {
      loading.value = true
      const allPass = await formsContext.validateAll()
      if (!allPass) {
        loading.value = false
        return
      }
      const formData = formsContext.composFormData()
      const payload = {
        shopId: shopId.value,
        ...formData,
      }

      try {
        if (!memberCenterAdId.value) await createMemberCenterAd(payload)
        if (memberCenterAdId.value) {
          payload.id = memberCenterAdId.value
          await updateMemberCenterAd(payload)
        }
        router.push({ name: 'MemberCenterBulletinList' })
      } catch (error) {
        window.$message.error(error)
        loading.value = false
      }
    }

    const onCancel = () => {
      router.push({
        name: 'MemberCenterBulletinList',
      })
    }

    onMounted(async () => {
      if (memberCenterAdId.value) await findMemberCenterAd()
      loaded.value = true
    })

    return { onCancel, onSubmit, loading, productData, loaded, context }
  },
})
</script>

<style lang="postcss" scoped>
.member-shop-point-exchange-product-edit {
  @apply flex flex-col gap-[20px];
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
