<template>
  <div class="member-shop-point-exchange-prodct-edit-info-block">
    <AiHintBlock
      v-if="!get(productData, 'id')"
      :hintLoading="hintLoading"
      @confirm="hintConfirm"
    />
    <BaseElForm ref="formRef" v-loading="hintLoading" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="佈告欄標題" prop="title">
        <template slot="label">
          佈告欄標題 <span class="text-sm text-gray-80">( 建議字數少於 32 )</span>
        </template>
        <BaseElInput v-model="formData.title" placeholder="請輸入" />
      </BaseElFormItem>
      <BaseElFormItem label="排序" prop="order">
        <BaseElInput v-model="formData.order" placeholder="請輸入排序" />
      </BaseElFormItem>
      <BaseElFormItem label="佈告期間" prop="date">
        <template slot="label">
          <FormItemTooltipLabel label="佈告期間" :tooltipWidth="200">
            佈告時間開始後，將於會員中心顯<br>
            示此佈告欄。佈告時間結束後，將<br>
            於會員中心隱藏此佈告欄
          </FormItemTooltipLabel>
        </template>
        <el-date-picker
          v-model="formData.date"
          editable
          type="datetimerange"
          range-separator="至"
          format="yyyy-MM-dd HH:mm"
          start-placeholder="開始時間"
          end-placeholder="結束時間"
        />
      </BaseElFormItem>
      <BaseElFormItem label="佈告欄版型" prop="type">
        <BaseElSelect v-model="formData.type" placeholder="請選擇">
          <BaseElSelectOption
            v-for="item in layoutConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.type === 'image'" label="圖片" prop="image" class="form-relative-label others">
        <template slot="label">
          圖片 <span class="text-sm text-gray-80">( 圖片顯示比例 42:9 )</span>
        </template>
        <UploadButton
          cyUploadBtn="service-img-upload-btn"
          cyUploadedImg="service-img-uploaded"
          :img="formData.image"
          :isAvatar="true"
          :width="(90 / 9 * 42) + 'px'"
          @change="loadImg"
        />
      </BaseElFormItem>
      <BaseElFormItem v-if="['text','textButton'].includes(formData.type)" label="佈告欄底色設定" prop="textBackgroundColor">
        <template slot="label">
          佈告欄底色設定 <span class="text-sm text-gray-80">( 文字為白色 #FFFFFF，底色建議選擇深色系 )</span>
        </template>
        <ColorPicker
          v-if="formData.textBackgroundColor"
          class="w-[80px]"
          :value.sync="formData.textBackgroundColor"
        />
      </BaseElFormItem>
      <section v-if="['text','textButton'].includes(formData.type)">
        <p class="text-sub text-gray-60">色系預覽</p>
        <div class="preview-area">
          <MemberCenterBulletinColorPreview
            :primary="systemColor.primaryColor"
            :secondary="systemColor.secondaryColor"
            :bulletinColor="formData.textBackgroundColor"
            :layout="formData.type"
          />
        </div>
      </section>
      <BaseElFormItem v-if="formData.type === 'textButton'" label="按鈕文字" prop="buttonText">
        <template slot="label">
          按鈕文字 <span class="text-sm text-gray-80">( 最多字數 4 )</span>
        </template>
        <BaseElInput v-model="formData.buttonText" placeholder="請輸入" />
      </BaseElFormItem>
      <BaseElFormItem label="點擊前往" prop="actionType">
        <BaseElSelect v-model="formData.actionType" placeholder="請選擇">
          <BaseElSelectOption
            v-for="item in computedActionTypeConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.actionType === 'link'" prop="actionLink">
        <BaseElInput v-model="formData.actionLink" placeholder="請輸入網址" />
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.actionType === 'memberGame'" prop="ActionMemberGame">
        <BaseElSelect v-model="formData.ActionMemberGame" placeholder="請選擇抽獎活動" value-key="id">
          <BaseElSelectOption
            v-for="item in memberGameList"
            :key="item.id"
            :label="`${item.name} ${item.enable ? '' : '(已結束)'}`"
            :value="item"
          />
        </BaseElSelect>
        <p v-if="formData.ActionMemberGame" class="reward-date-range">
          {{ renderRewardDateRange }}
          <span v-if="!isInRewardDateRange" class="alert-content">
            <SVGIcon
              class="alert-icon"
              icon="bulletin-stroke_alert"
              fill="#FEBE1F"
              width="12px"
              height="12px"
            />佈告期間未完全包含抽獎活動進行期間
            <el-tooltip
              :content="`抽獎活動「已結束」，仍可前往抽獎活動頁面，但無法參加抽獎`"
              placement="right"
              width="200px"
            >
              <p class="font-medium">
                (說明)
              </p>
            </el-tooltip>
          </span>
        </p>
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.actionType === 'clientAnnouncement'" prop="ActionClientAnnouncement">
        <BaseElSelect v-model="formData.ActionClientAnnouncement" placeholder="請選擇佈告資訊頁" value-key="id">
          <BaseElSelectOption
            v-for="item in filterbulletinList"
            :key="item.id"
            :label="item.label"
            :value="item"
          />
        </BaseElSelect>
        <p v-if="formData.ActionClientAnnouncement" class="bulletin-date-range">
          {{ renderBulletinDateRange }}
          <span v-if="isEarlyClosure && !isInWithinBulletinDateRange" class="alert-content">
            <SVGIcon
              class="alert-icon"
              icon="bulletin-stroke_alert"
              fill="#FEBE1F"
              width="12px"
              height="12px"
            />佈告期間會比資訊頁提前結束
            <el-tooltip
              :content="`佈告欄「不顯示」，但仍可以從資訊頁連結進入查看內容`"
              placement="right"
              width="200px"
            >
              <p class="font-medium">
                (說明)
              </p>
            </el-tooltip>
          </span>
          <span v-if="!isEarlyClosure && !isInWithinBulletinDateRange" class="alert-content">
            <SVGIcon
              class="alert-icon"
              icon="bulletin-stroke_alert"
              fill="#FEBE1F"
              width="12px"
              height="12px"
            />佈告期間未完全包含資訊頁公開期間
            <el-tooltip
              :content="`佈告資訊頁「不公開」，仍可前往佈告資訊頁，但無法查看內容`"
              placement="right"
              width="200px"
            >
              <p class="font-medium">
                (說明)
              </p>
            </el-tooltip>
          </span>
        </p>
      </BaseElFormItem>
    </BaseElForm>
    <ImageCropper
      v-if="modal.imageCropper"
      :image="formData.image"
      :ratio="[42,9]"
      @uploaded="getImage"
      @close="modal.imageCropper = false"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, computed, onMounted, nextTick, ref, watch, set } from 'vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import FormsContext from '../context'
import { isDigitRules, noEmptyRules, maxRules, urlRules } from '@/validation'
import { omit, omitBy, get, isEmpty, find } from 'lodash'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import store from '@/store'
import { useFetch } from '@/use/fetch'
import dayjs from '@/lib/dayjs'
import { actionTypeConfig, layoutConfig } from '@/config/bulletin'
import ColorPicker from '@/components/ColorPicker.vue'
import MemberCenterBulletinColorPreview from './MemberCenterBulletinColorPreview.vue'
import { GetClientPageConfig } from '@/api/member/memberCenter'
import { FindMemberCenterColorTheme } from '@/api/memberCenterColorTheme'
import { GetClientAnnouncement } from '@/api/bulletin'
import { systemMemberCenterColorThemesTemplate } from '@/config/memberCenter'
import { GetMemberGame } from '@/api/lottery/memberGame'
import { formatDate } from '@/utils/date'
import { usePermissions } from '@/use/permissions'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { hint } from '@/config/hint'

export default defineComponent({
  name: 'MemberCenterBulletinEditInfoBlock',
  components: {
    UploadButton,
    ImageCropper,
    FormItemTooltipLabel,
    ColorPicker,
    MemberCenterBulletinColorPreview,
    AiHintBlock,
  },
  props: {
    productData: { type: Object, default: () => ({}) },
    context: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { simpleFetch } = useFetch()
    const { checkAction } = usePermissions()
    const features = reactive({
      useMemberGame: computed(() => checkAction('admin.memberGameRecord.page')),
    })
    const computedActionTypeConfig = computed(() => {
      const omitList = []
      if (!features.useMemberGame) omitList.push('memberGame')
      return omit(actionTypeConfig, omitList)
    })
    const modal = reactive({
      imageCropper: false,
    })
    const configData = ref({})
    const shopId = computed(() => store.getters.shop)
    const formRef = ref(null)
    const systemColor = reactive({
      primaryColor: '',
      secondaryColor: '',
    })
    const formData = reactive({
      title: '',
      order: 100,
      date: null,
      publishedStartAt: null,
      publishedEndAt: null,
      type: '',
      image: null,
      textBackgroundColor: '',
      buttonText: '',
      actionType: null,
      actionLink: null,
      ActionMemberGame: null,
      ActionMemberGameId: null,
      ActionClientAnnouncement: null,
      ActionClientAnnouncementId: null,
    })
    const memberGameList = ref([])
    const bulletinList = ref([])

    const isWithinDateRange = (startAt, endAt, dateRange) => {
      const [startDate, endDate] = dateRange.map(dayjs)
      return dayjs(startAt).isAfter(startDate) && dayjs(endAt).isBefore(endDate)
    }
    const isInRewardDateRange = computed(() => {
      if (get(formData, 'date') && get(formData, 'ActionMemberGame')) {
        const ActionMemberGame = find(memberGameList.value, ({ id }) => id === get(formData.ActionMemberGame, 'id'))
        if (!ActionMemberGame) return false
        const actionMemberGameStartAt = get(ActionMemberGame, 'startAt')
        const actionMemberGameEndAt = get(ActionMemberGame, 'endAt')
        return isWithinDateRange(actionMemberGameStartAt, actionMemberGameEndAt, get(formData, 'date'))
      }
      return true
    })

    const isEarlyClosure = computed(() => {
      if (get(formData, 'date') && get(formData, 'ActionClientAnnouncement')) {
        const ActionClientAnnouncement = find(bulletinList.value, ({ id }) => id === get(formData.ActionClientAnnouncement, 'id'))
        if (!ActionClientAnnouncement) return false
        const actionClientAnnouncementEndAt = get(ActionClientAnnouncement, 'publishedEndAt')
        if (actionClientAnnouncementEndAt) return dayjs(get(formData, 'date[1]')).isBefore(dayjs(actionClientAnnouncementEndAt))
        else return true
      }
      return false
    })
    const isInWithinBulletinDateRange = computed(() => {
      if (get(formData, 'date') && get(formData, 'ActionClientAnnouncement')) {
        const ActionClientAnnouncement = find(bulletinList.value, ({ id }) => id === get(formData.ActionClientAnnouncement, 'id'))
        if (!ActionClientAnnouncement) return false
        const actionClientAnnouncementStartAt = get(ActionClientAnnouncement, 'publishedStartAt')
        const actionClientAnnouncementEndAt = get(ActionClientAnnouncement, 'publishedEndAt')
        if (actionClientAnnouncementEndAt) return isWithinDateRange(actionClientAnnouncementStartAt, actionClientAnnouncementEndAt, get(formData, 'date'))
        else return false
      }
      return false
    })

    const formRules = computed(() => {
      const rules = {
        title: [noEmptyRules()],
        order: [noEmptyRules(), isDigitRules()],
        date: [noEmptyRules()],
        type: [noEmptyRules()],
        actionType: [noEmptyRules()],
      }
      if (formData.type === 'image') {
        rules.image = [noEmptyRules()]
      }
      if (formData.type === 'text') {
        rules.textBackgroundColor = [noEmptyRules()]
      }
      if (formData.type === 'textButton') {
        rules.textBackgroundColor = [noEmptyRules()]
        rules.buttonText = [noEmptyRules(), maxRules(4)]
      }
      if (formData.actionType === 'link') {
        rules.actionLink = [noEmptyRules(), urlRules()]
      }
      if (formData.actionType === 'memberGame') {
        rules.ActionMemberGame = [noEmptyRules()]
      }
      if (formData.actionType === 'clientAnnouncement') {
        rules.ActionClientAnnouncement = [noEmptyRules()]
      }
      return rules
    })
    const compactData = computed(() => {
      const data = omitBy(omit(formData, ['image']), i => i === null)
      data.publishedStartAt = get(formData.date, '[0]') || undefined
      data.publishedEndAt = get(formData.date, '[1]') || undefined
      data.ImageId = get(formData.image, 'id') || undefined
      data.ActionMemberGameId = formData.actionType === 'memberGame' ? get(formData.ActionMemberGame, 'id') : null
      data.ActionClientAnnouncementId = formData.actionType === 'clientAnnouncement' ? get(formData.ActionClientAnnouncement, 'id') : null
      return { ...data }
    })

    const syncData = () => {
      const data = props.productData
      formData.title = data.title
      formData.order = data.order
      formData.date = [data.publishedStartAt, data.publishedEndAt]
      formData.type = data.type
      formData.image = data.Image
      formData.textBackgroundColor = data.textBackgroundColor
      formData.buttonText = data.buttonText
      formData.actionType = data.actionType
      formData.actionLink = data.actionType === 'link' ? data.actionLink : null
      formData.ActionMemberGame = data.actionType === 'memberGame' ? data.ActionMemberGame : null
      formData.ActionMemberGameId = data.actionType === 'memberGame' ? data.ActionMemberGameId : null
      formData.ActionClientAnnouncement = data.actionType === 'clientAnnouncement' ? data.ActionClientAnnouncement : null
      formData.ActionClientAnnouncementId = data.actionType === 'clientAnnouncement' ? data.ActionClientAnnouncementId : null
    }

    const renderRewardDateRange = computed(() => {
      const ActionMemberGame = find(memberGameList.value, ({ id }) => id === get(formData.ActionMemberGame, 'id'))
      if (!ActionMemberGame) return ''
      return `抽獎活動進行期間：${formatDate(ActionMemberGame.startAt)} - ${formatDate(ActionMemberGame.endAt)}` || ''
    })

    const renderBulletinDateRange = computed(() => {
      const ActionClientAnnouncement = find(bulletinList.value, ({ id }) => id === get(formData.ActionClientAnnouncement, 'id'))
      if (!ActionClientAnnouncement) return ''
      if (ActionClientAnnouncement.publishedEndAt) return `資訊頁公開期間：${formatDate(ActionClientAnnouncement.publishedStartAt)} - ${formatDate(ActionClientAnnouncement.publishedEndAt)}` || ''
      else return `資訊頁公開期間：${formatDate(ActionClientAnnouncement.publishedStartAt)} - 無期限` || ''
    })

    const checkPublished = async (item) => {
      const [publishedStartAt, publishedEndAt] = [get(item, 'publishedStartAt'), get(item, 'publishedEndAt')]
      if (publishedEndAt) return dayjs().isAfter(publishedStartAt) && dayjs().isBefore(publishedEndAt) ? '' : '(未公開)'
      else return ''
    }

    const getImage = (data) => {
      formData.image = data
      modal.imageCropper = false
    }
    const loadImg = (img) => {
      formData.image = img
      modal.imageCropper = true
    }

    watch(formData, () => {
      console.log(compactData.value)
      FormsContext.setFormData('info', { ...compactData.value })
    })

    onMounted(async () => {
      await nextTick()
      if (features.useMemberGame) await getMemberGame()
      await getBulletinList()
      simpleFetch(GetClientPageConfig, { shopId: shopId.value }, (res) => {
        configData.value = res
        if (get(configData.value, 'colorStyle') === 'custom') {
          simpleFetch(FindMemberCenterColorTheme, { shopId: shopId.value, id: get(configData.value, 'CustomColorStyleId') },
            (res) => {
              systemColor.primaryColor = get(res, 'primaryColor')
              if (isEmpty(get(formData, 'textBackgroundColor')))formData.textBackgroundColor = get(res, 'primaryColor')
              systemColor.secondaryColor = get(res, 'secondaryColor')
            },
          )
        } else {
          const current = find(systemMemberCenterColorThemesTemplate, item => item.name === get(configData.value, 'colorStyle'))
          systemColor.primaryColor = get(current, 'primaryColor')
          if (isEmpty(get(formData, 'textBackgroundColor'))) formData.textBackgroundColor = get(current, 'primaryColor')
          systemColor.secondaryColor = get(current, 'secondaryColor')
        }
      })
      FormsContext.setFormRef('info', formRef.value)

      if (get(props.productData, 'id')) syncData()
    })
    const getMemberGame = async () => {
      const [res, err] = await GetMemberGame({
        shopId: shopId.value,
        start: 0,
      })
      if (err) return this.$message.error(err)
      memberGameList.value = res
    }
    const getBulletinList = async () => {
      const [res, err] = await GetClientAnnouncement({
        shopId: shopId.value,
        start: 0,
      })
      if (err) return this.$message.error(err)
      bulletinList.value = res
    }
    // const filterbulletinList = computed(() => {
    //   return map(bulletinList.value, (item) => {
    //     const published = checkPublished(item)
    //     return {
    //       ...item,
    //       label: `${item.title} ${published}`,
    //     }
    //   })
    // })
    const filterbulletinList = ref([])

    watch(bulletinList, async (newBulletinList) => {
      filterbulletinList.value = await Promise.all(newBulletinList.map(async item => {
        const published = await checkPublished(item)
        return {
          ...item,
          label: `${item.title} ${published}`,
        }
      }))
    }, { immediate: true })
    const { configurationHint, hintLoading, setData } = useAi()
    const hintConfirm = async (payload) => {
      const res = await configurationHint(get(hint, 'memberCenterAd.key'), payload)
      if (!res) {
        hintLoading.value = false
        return
      }
      setData(formData, res)
      const { publishedStartAt, publishedEndAt, actionType, actionLink, memberGameId, clientAnnouncementId } = res.hint
      if (publishedStartAt && publishedEndAt) set(formData, 'date', [publishedStartAt, publishedEndAt])
      set(formData, 'actionLink', actionType === 'link' ? actionLink : null)
      set(formData, 'ActionMemberGame', actionType === 'memberGame' ? find(memberGameList.value, { id: memberGameId }) : null)
      set(formData, 'ActionClientAnnouncement', actionType === 'clientAnnouncement' ? find(bulletinList.value, { id: clientAnnouncementId }) : null)
    }
    return {
      get,
      formRef,
      formData,
      formRules,
      getImage,
      loadImg,
      modal,
      layoutConfig,
      actionTypeConfig,
      computedActionTypeConfig,
      systemColor,
      memberGameList,
      // bulletinList,
      filterbulletinList,
      renderRewardDateRange,
      renderBulletinDateRange,
      isInRewardDateRange,
      checkPublished,
      isEarlyClosure,
      isInWithinBulletinDateRange,
      hintLoading,
      hintConfirm,
    }
  },
})
</script>

<style lang="postcss" scoped>
.select-popper {
  @apply hidden;
}

::v-deep .el-alert__content{
  padding: 0 20px;
}

::v-deep .el-alert__description{
  @apply leading-[1];
}
::v-deep .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
  @apply w-[560px];
}
.preview-area {
  @apply bg-[#F5F7FA] rounded-[4px] py-[20px] w-[560px];
}
.reward-date-range, .bulletin-date-range {
  @apply text-sm text-gray-80 leading-normal mt-[8px]
}
.alert-content {
  @apply text-sm text-[#FEBE1F] flex items-center leading-normal
}
.alert-icon {
  @apply mr-[4px]
}
</style>
