<template>
  <div class="member-center-color-theme-preview">
    <div v-if="layout === 'text'" class="home relative">
      <SVGIcon
        class="test"
        icon="bulletin-home_text_secondary"
        width="100px"
        height="220px"
        :fill="secondary"
      />
      <SVGIcon class="test" icon="bulletin-home_text_primary" width="100" height="220" :fill="primary" />
      <SVGIcon class="test" icon="bulletin-home_text_newsbg" width="100" height="220" :fill="bulletinColor" />
      <img class="test" src="@/assets/svgIcon/bulletin/home_text_mockup.png" style="width: 100px">
    </div>
    <div v-if="layout === 'textButton'" class="home relative">
      <SVGIcon
        class="test"
        icon="bulletin-home_button_secondary"
        width="100px"
        height="220px"
        :fill="secondary"
      />
      <SVGIcon class="test" icon="bulletin-home_button_primary" width="100" height="220" :fill="primary" />
      <SVGIcon class="test" icon="bulletin-home_button_newsbg" width="100" height="220" :fill="bulletinColor" />
      <img class="test" src="@/assets/svgIcon/bulletin/home_button_mockup.png" style="width: 100px">
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MemberCenterBulletinColorPreview',
  props: {
    primary: String,
    secondary: String,
    bulletinColor: String,
    layout: String,
  },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.member-center-color-theme-preview {
    @apply flex items-center justify-center;
}
.home {
    @apply bg-white w-[100px] h-[220px];
}
.test {
    @apply absolute top-0 left-0 right-0 bottom-0 m-auto;
}
</style>
